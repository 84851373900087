import React, {useEffect, useState} from 'react'
import useGeneralStore from "../Stores/store";
import {
    getVariableWithNamespace,
    updateGlobalVar, updateGlobalVarByNamespace
} from "../jsonData/DataWrangler";
import { AiOutlineCheckCircle } from 'react-icons/ai'
import produce, { setAutoFreeze } from "immer";
import metadata from './../metadata.json';

const Inventory = ({ inventoryItems }) => {

    const [setInventoryState, inventorySelectableAmount] = useGeneralStore(state => [state.setInventoryState, state.inventorySelectableAmount]);
    
    const [currentInventory, setCurrentInventory] = useState(inventoryItems);
    const [checked, setChecked] = useState([]);
    
    const prettyName = metadata.inventoryNames.find(inv => inv.identifier === currentInventory.Namespace).prettyTitle;
    
    useEffect(() => {
        setChecked(currentInventory.Variables.filter(e => e.Value === 2));
    }, [])
    
    const progressConversation = () => {
        
        updateGlobalVar("InventoryItemsSelected", checked.length.toString(), ["GameSettings"]);
        let cleanList = getVariableWithNamespace('PickedInventoryItems')
        const updatedPickedItemList = produce(cleanList, draft => {
            for (let i = 0; i < checked.length; i++) {
                draft.Variables[i].Value = "\"" + checked[i].Variable + "\"";
            }
        })

        updateGlobalVarByNamespace(updatedPickedItemList.Namespace, updatedPickedItemList);
        
        setInventoryState(false);
        inventoryItems.callbackFunction();
    }

    const selectOption = (item) => {
        const newVal = item.Value === "1" ? "2" : "1";

        setAutoFreeze(false)
        const newInv = produce(currentInventory, draft => {
            if (inventorySelectableAmount === 1) {
                for (let i = 0; i < draft.Variables.length; i++) {
                    if (draft.Variables[i].Value === "2")
                        draft.Variables[i].Value = "1";
                }
            }
            
            draft.Variables.filter(e => e.Variable === item.Variable)[0].Value = newVal;
        })
        setCurrentInventory(newInv);
        
        setChecked(newInv.Variables.filter(e => e.Value === "2"));
    }

    const getStyling = (value) => {
        
        if (value === 1) {
            return "bg-persona-greenDark bg-opacity-30 transform transition duration-200 hover:scale-105 cursor-pointer"
        }
        else if (value === 2) {
            return "bg-persona-greenDark opacity-100 transform transition duration-200 hover:scale-105 cursor-pointer"
        }
        else {
            return "bg-persona-greenLight opacity-30 line-through"
        }
    }

    return (
        <div className="bg-black bg-opacity-70 absolute w-screen h-screen top-0 left-0 flex z-20 px-2">
            <div className="bg-persona relative rounded-3xl shadow-lg w-full mx-auto max-w-lg my-auto py-5 px-8 flex flex-col relative
                border-2 space-y-2">
                <div className="font-semibold text-lg text-persona-white flex-grow-0 text-sm capitalize">{prettyName}</div>
                {
                    currentInventory.Variables.map((item, key) => {
                        const intVal = parseInt(item.Value);
                        return ( intVal > 0 &&
                            <div key={key}
                                 className={`${getStyling(intVal)} text-persona-white rounded-md shadow-lg px-6 h-12 py-2 flex flex-row items-center`}
                                 onClick={() => intVal !== 3 && selectOption(item)}>
    
                                <p className="flex-grow">
                                    {intVal === 2 ? "> " : ""} {item.Description}
                                </p>
                                {intVal === 2 &&
                                    <AiOutlineCheckCircle className="w-8 h-8"/>
                                }
                            </div>
                        )
                    })
                }

                <div className="flex flex-wrap gap-2">
                    <div onClick={progressConversation}
                         className={`flex-grow ${checked.length === 0 ? "bg-gray-500" : "bg-green-600"} text-center font-semibold text-white px-5 cursor-pointer rounded-lg my-auto py-2`}>
                        Versturen
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Inventory