import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';

import 'react-dropdown/style.css';
import { useHistory } from "react-router-dom";
import metadata from '../metadata.json';

import { ReactComponent as Logo } from '../images/MadLogicLogo.svg';
import { RiArrowGoBackLine } from 'react-icons/ri';
import Dropdown from 'react-dropdown';

const dropdownOptions = metadata.startQRCodes;
const defaultOption = dropdownOptions[0];

const ScanScreen = ({ handleScan }) => {

    const [scanError, setScanError] = useState('');
    const [scanResult, setScanResult] = useState('');

    const history = useHistory();

    useEffect(() => {
        if (scanResult.length > 0) {
            handleScan(scanResult);
        }
    }, [scanResult])

    function handleScanBtn(data) {
        if (data) {
            setScanResult(data);
            setScanError('');
        }
    }

    function handleScanError(err) {
        setScanError('Error ' + err);
        console.log("scan error: " + err);
    }

    function handleManualSubmit(event) {
        event.preventDefault();

        handleScan(scanResult);
    }

    function handleManualInputChange(e) {
        setScanResult(e.target.value);
    }

    function onChangeDropdownValue(val) {

        setScanResult(val.value);
    }
    
    function onClickButton(val) {

        setScanResult(val);
    }

    return (
        <div className="h-screen mx-auto flex flex-col relative">

            {/* <BackgroundDecorations /> */}

            <QrReader className=""
                delay={300}
                showViewFinder={false}
                onError={handleScanError}
                onScan={handleScanBtn}
            />

            <div className="w-full h-0 pb-0.5 absolute">
                <svg className="text-persona-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.197 178.197"><path d="M0 0v49.895h14.256v-7.128H7.128V7.127h35.64v7.129h7.127V0zm71.279 0v14.256h7.128V0zm14.256 0v7.128h7.128V0zm28.511 0v7.128H99.79v7.128h21.384V0zm14.256 0v14.256h7.128V7.128h35.64v35.64h-7.129v7.127h14.256V0zM57.023 7.128v7.128h7.128V7.128zM0 57.023v7.128h7.128v7.128h7.128V57.023zm7.128 14.256H0v7.128h7.128zm0 7.128v42.767h7.128V78.407zm156.813-21.384v21.384h7.128v7.127h-7.128v21.384h7.128v7.128h7.128V99.79h-7.128v-7.127h7.128V71.279h-7.128V57.023zM0 128.302v49.895h49.895V163.94h-7.128v7.128H7.128v-35.64h7.128v-7.127zm163.941 0v14.256h14.256v-14.256zm0 21.383v7.128h7.128v14.256h-14.256v-7.128h-49.895v7.128h14.256v7.128h7.128v-7.128h7.128v7.128h42.767v-28.512zm-57.023 21.384H85.535v7.128h21.383zm-21.383 0v-7.128h-7.128v7.128zm-7.128 0h-7.128v-7.128H57.023v14.256h21.384z" /></svg>
            </div>

            {/* Debug view scan result and error ---------------------------------- */}
            {/* <div className="text-main-white p-2">
                <p>result: [{scanResult}]</p>
                <p>error: [{scanError}]</p>
            </div> */}

            {/* Debug dropdown ---------------------------------- */}
            {process.env.REACT_APP_DEBUG_MODE === 'true' &&
                <>
                    <form onSubmit={handleManualSubmit} className="mb-2 flex flex-row space-x-2 mx-2 pt-3">
    
                        <Dropdown options={dropdownOptions} placeholder="Select an option" onChange={onChangeDropdownValue} value={defaultOption} className="flex-1 m-auto" />
                    </form>
                    <div className="w-full flex flex-initial flex-wrap">
                        {
                            dropdownOptions.map((item, key) => {
                                return (
                                    <div key={key} className="flex-grow p-2 m-1 rounded-sm persona-btn persona-btn-hover my-1 cursor-pointer"
                                         onClick={() => onChangeDropdownValue(item)}>
                                        <p className="m-auto text-lg text-center">{item.label}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }

            {
                scanError !== '' &&
                <p className="bg-red-400 text-center py-3 mt-3">{scanError}</p>
            }

            {/* logo ---------------------------------- */}
            <div className="m-auto mt-6">
                {
                    metadata.useLogo === 'true' ?
                        <Logo className="h-40 mx-auto " /> : null
                }
            </div>

            <div
                className="bg-persona-greenLight my-5 rounded-full mx-auto w-20 h-20 flex items-center shadow-lg cursor-pointer"
                onClick={() => history.goBack()}>
                <RiArrowGoBackLine className="mx-auto h-12 w-12 m-8 text-persona-white" />
            </div>
        </div>
    )
}

export default ScanScreen